<template>
  <vue-paystack
    :amount="(amount * 100).toFixed(0) * 1"
    :reference="reference"
    :paystackkey="paystackKey"
    :channels="channels"
    :email="email"
    :firstname="firstName"
    :lastname="firstName"
    :close="close"
    :callback="callback"
  >
    <slot>Make Payment</slot>
  </vue-paystack>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    amount: {
      type: [String, Number],
      default: 0,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    VuePaystack: () => import("vue-paystack"),
  },
  computed: {
    ...mapState("session", ["keys"]),

    channels() {
      return ["card"];
    },
    email() {
      return this.user?.email;
    },
    firstName() {
      return this.user?.name.split(" ")[0];
    },
    lastName() {
      return this.user?.name.split(" ").pop();
    },
    paystackKey() {
      return this.keys?.paystack_public_key;
    },
    reference() {
      var string = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 32; i++) {
        string += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return string;
    },
  },
};
</script>